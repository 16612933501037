import instagram from '../../assets/instagram.png';
import map_mark from '../../assets/map_mark.png';
import phone from '../../assets/phone.png';
import Logo from '../../assets/logo_horizontal.png';

export function Footer() {
  return (
    <div className="footer" style={{ backgroundColor: '#000', padding: '32px 0px', color: 'white' }}>
      <div className="container">
        <div className="row">
            <div>
              <img src={Logo} height='33' alt="Liu Eco Tyre Logo" />
            </div>
          <div className="col-md-4 col-sm-12">
            <h5>Contato</h5>
            <span style={{ display: 'flex', alignItems: 'center', marginBottom: 16  }}>
              <img src={map_mark} alt="#" width={25} height={25} style={{ marginRight: 8  }} /> 
              Rod. BR 235 KM 32, nº 10, Povoado Ponto Chique, Areia Branca, SE. 
            </span>
            <p>
              <img src={phone} alt="#" width={25} height={25} style={{ marginRight: 8  }} /> <a href="tel:5579988257474" style={{ color: 'white' }}>(79) 98825-7474</a>
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <h5>Redes Sociais</h5>
            <p>
              <a href="https://www.instagram.com/liuecotyre/">
                <img src={instagram} alt="#" width={30} />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}