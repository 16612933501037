import { ButtonContact } from "../ButtonContact";

export function MapContact() {
  return (
    <div className="container"style={{ marginTop: 64, marginBottom: 64, overflow: 'hidden' }}>
      <div className="row">
        <div className="col-md-4 pr-2">
          <h4 style={{ fontWeight: 'bold' }}>VENHA NOS VISITAR</h4>
          <div style={{ fontSize: 11.11, color: 'white' }}>
            NOSSO ENDEREÇO
          </div>
          <p>
            <strong>Endereço:</strong> Rod. BR 235 KM 32, nº 10, Povoado Ponto Chique, Areia Branca, SE.
            <br />
            <strong>Telefone: </strong>
            <a href="tel:5579988257474">(79) 98825-7474</a>
            <br />
          </p>
          <ButtonContact />
        </div>
        <div className="col-md-8">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe title="Liu Eco Tyre" className="gmap_iframe" height={350} width="100%" frameBorder="0" scrolling="no" src="https://maps.google.com/maps?width=1025&amp;height=600&amp;hl=en&amp;q=Liu%20Eco%20Tyre&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
              </iframe>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
}