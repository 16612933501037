import { useEffect, useState } from 'react';

import banner1 from '../../assets/banner1.webp';
import banner1Mobile from '../../assets/banner1-mobile.webp';
import banner2 from '../../assets/banner2.webp';
import banner2Mobile from '../../assets/banner2-mobile.webp';
import { getWindowDimensions } from '../../utils/dimensions';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import "./styles.css";

export default function FrontBanner() {

	function useWindowDimensions() {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

		useEffect(() => {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}, []);

		return windowDimensions;
	}

	const isMobile = useWindowDimensions().width < 500;

	return (
		<Carousel infiniteLoop swipeable showThumbs={false} autoPlay interval={5000} showStatus={false}>
			<div>
				<img className='d-block w-100' src={isMobile ? banner1Mobile : banner1} alt="Procurando por pneus para carro e moto? Aqui você encontra!" />
			</div>
			<div>
				<img className='d-block w-100' src={isMobile ? banner2Mobile : banner2} alt="Fale Conosco pelo número (79) 98825-7424 ou venha nos visitar." />
			</div>
		</Carousel>
	);
}
