import { db } from '../database';
import whatsapp from '../../assets/whatsapp.png';
import { useState } from 'react';

import motorcycle from '../../assets/icons/motorcycle.svg';
import all from '../../assets/icons/all.svg';
import van from '../../assets/icons/van.svg';
import pickup from '../../assets/icons/pickup.svg';
import car from '../../assets/icons/car.svg';
import arrowUp from '../../assets/icons/arrow-up.svg';
import arrowDown from '../../assets/icons/arrow-down.svg'

import './styles.css';

export type TyreType = 'all' | 'van' | 'pickup' | 'moto' | 'passeio' | null;

function importAll(r:any) {
  let images:any = {};
  r.keys().map((item:any, index:any) => {
    return images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  (require as any).context("../../assets/tires/", false, /\.(webp)$/)
);

export function SellingTable() {
  const [tyreType, setTyreType] = useState<TyreType>('all');
  const [search, setSearch] = useState<string>('');
  const [selectedPriceFilter, setSelectedPriceFilter] = useState<'up' | 'down' | null>(null);

  const filteredItems = db
    .filter((i) => tyreType === 'all' ? true : i.type === tyreType)
    .filter(i => search === '' ? true : i.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      if (selectedPriceFilter === 'up') {
        return b.price - a.price;
      } else if (selectedPriceFilter === 'down') {
        return a.price - b.price;
      } else {
        return 0;
      }
    });


  return (
    <div className='container'>
      <div className='row'>
        <div style={{ paddingTop: 16, paddingBottom: 16}}>
          <h5 style={{ fontWeight: 'bold', color: '#404142' }}>CONFIRA NOSSOS PRODUTOS</h5>
        </div>
        <div className='col-sm-12 col-md-2'>
          <div className="input-group mb-3">
            <input value={search} onChange={(i) => setSearch(i.target.value)} type="text" className="form-control" placeholder="Pesquisar" aria-label="Pesquisar" aria-describedby="button-addon2" />
          </div>
          <div>
            <span style={{ color: '#8c8d8f', fontSize: 12 }}>VALOR</span>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: 4
            }}>
              <button
                onClick={
                  () =>
                    selectedPriceFilter === 'up'
                      ? setSelectedPriceFilter(null)
                      : setSelectedPriceFilter('up')
                }
                className={`sidebar-menu-item ${selectedPriceFilter === 'up' && 'sidebar-menu-active'}`}>
                <img src={arrowUp} alt="" className={`svg-icon ${selectedPriceFilter === 'up' && 'svg-icon-white'}`} ></img>
                Maior preço
              </button>
              <button
                onClick={
                  () =>
                    selectedPriceFilter === 'down'
                      ? setSelectedPriceFilter(null)
                      : setSelectedPriceFilter('down')
                }
                className={`sidebar-menu-item ${selectedPriceFilter === 'down' && 'sidebar-menu-active'}`}>
                <img src={arrowDown} alt="" className={`svg-icon ${selectedPriceFilter === 'down' && 'svg-icon-white'}`} ></img>
                Menor preço
              </button>
            </div>
          </div>

          <span style={{ color: '#8c8d8f', fontSize: 12 }}>TIPOS</span>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: 4
          }}>
            <button
              onClick={() => setTyreType('all')}
              className={`sidebar-menu-item ${tyreType === 'all' && 'sidebar-menu-active'}`}>
              <img src={all} alt="" className={`svg-icon ${tyreType === 'all' && 'svg-icon-white'}`} ></img>
              Todos
            </button>
            <button
              onClick={() => setTyreType('van')}
              className={`sidebar-menu-item ${tyreType === 'van' && 'sidebar-menu-active'}`}>
              <img src={van} alt="" className={`svg-icon ${tyreType === 'van' && 'svg-icon-white'}`} ></img>
              Van
            </button>
            <button
              onClick={() => setTyreType('pickup')}
              className={`sidebar-menu-item ${tyreType === 'pickup' && 'sidebar-menu-active'}`}>
              <img src={pickup} alt="" className={`svg-icon ${tyreType === 'pickup' && 'svg-icon-white'}`} ></img>
              Pickup
            </button>
            <button
              onClick={() => setTyreType('moto')}
              className={`sidebar-menu-item ${tyreType === 'moto' && 'sidebar-menu-active'}`}>
              <img src={motorcycle} alt="" className={`svg-icon ${tyreType === 'moto' && 'svg-icon-white'}`}></img>
              <span>Moto</span>
            </button>
            <button
              onClick={() => setTyreType('passeio')}
              className={`sidebar-menu-item ${tyreType === 'passeio' ? 'sidebar-menu-active' : 'sidebar-menu-default'}`}>
              <img src={car} alt="" className={`svg-icon ${tyreType === 'passeio' && 'svg-icon-white'}`} ></img>
              Passeio
            </button>
          </div>
        </div>

        <div className="row g-0 col-sm-12 col-md-10">
          {
            !filteredItems.length
              ? <span>
                Nenhum pneu encontrado para a sua busca.
              </span>
              : filteredItems.map((item, index, arr) => {
                return (
                  <div className="col-6 col-sm-6 col-md-4 col-lg-3" style={{ display: 'flex' }}>
                    <div
                      key={index}
                      className="tyre-card">
                      <img style={{ maxWidth: '100%', maxHeight: 200, height: 200, objectFit: 'contain' }} src={images[item.thumb+".webp"]} alt={item.name} />
                      <span className="tyre-title">{item.name}</span>
                      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}>
                        <span className="tyre-price">R$ {(Math.round(item.price * 100) / 100).toFixed(2)}</span>
                        <span className='tyre-price-complement'>somente atacado</span>
                      </div>
                      <a 
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'none' }}
                        href={`https://api.whatsapp.com/send?phone=5579988257474&text=Olá, gostaria de comprar o ${item.name}.`}>
                        <button
                          className="tyre-buy-btn">
                          <img alt="FALE CONOSCO" src={whatsapp} className="tyre-buy-btn-icon" />
                          COMPRAR
                        </button>
                      </a>
                    </div>
                  </div>
                );
              })
          }
        </div>
      </div>
    </div>
  );
}