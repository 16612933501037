import { Footer } from "./components/Footer";
import FrontBanner from "./components/FrontBanner";
import Header from "./components/Header/Header";
import { MapContact } from "./components/MapContact";
import { Presentation } from "./components/Presentation";
import { Recomendations } from "./components/Recomendations";
import { SellingTable } from "./components/SellingTable";

function App() {

  return (
    <div className="App">
      <Header />
      <FrontBanner />
      <SellingTable />
      <Presentation />
      <Recomendations />
      <MapContact />
      <Footer />
    </div>
  );
}

export default App;
