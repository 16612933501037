import './styles.css';

export function Presentation() {
  return (
    <div className='presentation'>
      <div className="container" style={{ marginTop: 64, padding: '64px 0px 64px 0px' }}>
        <div className="row g-4">
          <div className="text-container col-md-6 col-sm-12" style={{ padding: '32px'  }}>
            <h3 style={{ fontWeight: 'bold' }}>NÓS TEMOS O QUE VOCÊ PRECISA</h3>
            <div>
              Nossos pneus remolds são feitos com os materiais de alta qualidade e durabilidade.<br />
              Com carcaças selecionadas através de uma inspeção rigorosa.<br />
              Todos os nossos pneus passam por uma série de testes e recebem a aprovação e o selo do INMETRO.
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
              <iframe width="100%" height="320" src="https://www.youtube.com/embed/y8Bkk42-lOs?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

// interface ItemProps {
//   src: string;
//   text: string;
// }

// function Item(props: ItemProps) {
//   return (
//     <div style={{ display: 'flex', alignItems: 'center', columnGap: 8, marginBottom: 16 }}>
//       <img style={{ width: 35, height: 'auto' }} alt="#" src={props.src}></img>
//       <div style={{ fontWeight: 'bold' }}>{props.text}</div>
//     </div>
//   )
// }