
export const db = [
  {
    id: 38,
    name: 'VELOX 20W50 MINERAL CAIXA C/24 UNIDADES',
    price: 260,
    type: null,
    thumb: 'velox-20w50'
  },
  {
    id: 39,
    name: 'VOIL DIESEL 15W40 CAIXA C/24 UNIDADES',
    price: 260,
    type: null,
    thumb: 'voil-diesel-15w40'
  },
  {
    id: 40,
    name: 'VOIL SUPER MOTO 4T 20W50 CAIXA C/24 UNIDADES',
    price: 260,
    type: null,
    thumb: 'voil-super-moto-4t-20w50'
  },
  {
    id: 45,
    name: 'VOIL 20W50 MINERAL CAIXA C/24 UNIDADES',
    price: 260,
    type: null,
    thumb: 'voil-20w50-mineral'
  },
  {
    id: 41,
    name: 'LUB-X 20W50 MOTOCUCLES 4T CAIXA C/24 UNIDADES',
    price: 260,
    type: null,
    thumb: 'lub-x-20w50'
  },
  {
    id: 1,
    name: 'PNEU LIU ECO TYRE 175/70/13',
    price: 150,
    type: 'passeio',
    thumb: '1757013'
  },
  {
    id: 2,
    name: 'PNEU LIU ECO TYRE 185/70/13',
    price: 165,
    type: 'passeio',
    thumb: '1857013'
  },
  {
    id: 3,
    name: 'PNEU LIU ECO TYRE 175/65/14',
    price: 150,
    type: 'passeio',
    thumb: '1756514'
  },
  {
    id: 4,
    name: 'PNEU LIU ECO TYRE 185/R14',
    price: 200,
    type: 'passeio',
    thumb: '185-r14'
  },
  {
    id: 5,
    name: 'PNEU LIU ECO TYRE 175/70/14',
    price: 170,
    type: 'passeio',
    thumb: '1757014'
  },
  {
    id: 42,
    name: 'PNEU LIU ECO TYRE 175/70/14 ATR',
    price: 170,
    type: 'passeio',
    thumb: '205-60-15-atr'
  },
  {
    id: 6,
    name: 'PNEU LIU ECO TYRE 185/65/14',
    price: 170,
    type: 'passeio',
    thumb: '1856514'
  },
  {
    id: 7,
    name: 'PNEU LIU ECO TYRE 185/70/14',
    price: 170,
    type: 'passeio',
    thumb: '185-70-14'
  },
  {
    id: 8,
    name: 'PNEU LIU ECO TYRE 185/65/15',
    price: 150,
    type: 'passeio',
    thumb: '1856515'
  },
  {
    id: 9,
    name: 'PNEU LIU ECO TYRE 195/50/15',
    price: 160,
    type: 'passeio',
    thumb: '195-50-15'
  },
  {
    id: 10,
    name: 'PNEU LIU ECO TYRE 195/55/15',
    price: 150,
    type: 'passeio',
    thumb: '1955515'
  },
  {
    id: 11,
    name: 'PNEU LIU ECO TYRE 195/60/15',
    price: 150,
    type: 'passeio',
    thumb: '195-60-15'
  },
  {
    id: 43,
    name: 'PNEU LIU ECO TYRE 195/65/15',
    price: 170,
    type: 'passeio',
    thumb: '1956515'
  },
  {
    id: 12,
    name: 'PNEU LIU ECO TYRE 205/65/15',
    price: 200,
    type: 'passeio',
    thumb: '2056515'
  },
  {
    id: 13,
    name: 'PNEU LIU ECO TYRE 205/60/15',
    price: 200,
    type: 'passeio',
    thumb: '2056015'
  },
  {
    id: 14,
    name: 'PNEU LIU ECO TYRE 195/55/16',
    price: 150,
    type: 'passeio',
    thumb: '1955516'
  },
  {
    id: 15,
    name: 'PNEU LIU ECO TYRE 205/55/16',
    price: 150,
    type: 'passeio',
    thumb: '2055516'
  },
  {
    id: 16,
    name: 'PNEU LIU ECO TYRE 205/60/16',
    price: 200,
    type: 'passeio',
    thumb: '2056016'
  },
  {
    id: 17,
    name: 'PNEU LIU ECO TYRE 235/60/16',
    price: 245,
    type: 'passeio',
    thumb: '2356016'
  },
  {
    id: 18,
    name: 'PNEU LIU ECO TYRE 205/40/17',
    price: 265,
    type: 'passeio',
    thumb: '205-40-17'
  },
  {
    id: 19,
    name: 'PNEU LIU ECO TYRE 205/45/17',
    price: 265,
    type: 'passeio',
    thumb: '205-45-17'
  },
  {
    id: 20,
    name: 'PNEU LIU ECO TYRE 7.50R16',
    price: 400,
    type: 'agricola',
    thumb: '7-50-r16'
  },
  {
    id: 21,
    name: 'PNEU LIU ECO TYRE 90/90/19',
    price: 85,
    type: 'moto',
    thumb: '90-90-19'
  },
  {
    id: 22,
    name: 'PNEU LIU ECO TYRE 110/90/17',
    price: 90,
    type: 'moto',
    thumb: '100-90-18'
  },
  {
    id: 23,
    name: 'PNEU LIU ECO TYRE 80/100/14',
    price: 70,
    type: 'moto',
    thumb: '80-100-14'
  },
  {
    id: 24,
    name: 'PNEU LIU ECO TYRE 250/17',
    price: 70,
    type: 'moto',
    thumb: '250-17'
  },
  {
    id: 25,
    name: 'PNEU LIU ECO TYRE 90/90/18',
    price: 70,
    type: 'moto',
    thumb: '90-90-18'
  },
  {
    id: 26,
    name: 'PNEU LIU ECO TYRE 275/18',
    price: 70,
    type: 'moto',
    thumb: '275-18'
  },
  {
    id: 27,
    name: 'PNEU LIU ECO TYRE 100/90/18',
    price: 75,
    type: 'moto',
    thumb: '100-90-18'
  },
  {
    id: 28,
    name: 'PNEU LIU ECO TYRE 130/70/17',
    price: 95,
    type: 'moto',
    thumb: '130-70-17'
  },
  {
    id: 29,
    name: 'PNEU LIU ECO TYRE 100/80/17',
    price: 85,
    type: 'moto',
    thumb: '100-80-17'
  },
  {
    id: 30,
    name: 'PNEU LIU ECO TYRE 225/75/15',
    price: 335,
    type: 'pickup',
    thumb: '225-75-15'
  },
  {
    id: 46,
    name: 'PNEU LIU ECO TYRE 225/65/17',
    price: 290,
    type: 'pickup',
    thumb: '2256517'
  },
  {
    id: 31,
    name: 'PNEU LIU ECO TYRE 235/75/15',
    price: 380,
    type: 'pickup',
    thumb: '235-75-15'
  },
  {
    id: 32,
    name: 'PNEU LIU ECO TYRE 235/70/16',
    price: 305,
    type: 'pickup',
    thumb: '235-75-15'
  },
  {
    id: 33,
    name: 'PNEU LIU ECO TYRE 245/70/16',
    price: 340,
    type: 'pickup',
    thumb: '2457016'
  },
  {
    id: 34,
    name: 'PNEU LIU ECO TYRE 265/70/16',
    price: 340,
    type: 'pickup',
    thumb: '265-70-16'
  },
  {
    id: 35,
    name: 'PNEU LIU ECO TYRE 265/65/17',
    price: 340,
    type: 'pickup',
    thumb: '245-70-16'
  },
  {
    id: 36,
    name: 'PNEU LIU ECO TYRE 205/70/15',
    price: 195,
    type: 'van',
    thumb: '205-70-15'
  },
  {
    id: 37,
    name: 'PNEU LIU ECO TYRE 205/75/16',
    price: 275,
    type: 'van',
    thumb: '205-70-15'
  }
];